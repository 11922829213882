/* 外部方法 */
import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import streamStateLogService from '@/api/ajax/streamStateLogService';
import type { TOutputStateExtended } from '@sms/common/interfaces/ICustomOutput';
import type StreamSetupSet from '@sms/common/models/StreamSetupSet';

export default defineStore('stream', () => {
  const statusMap = reactive<Record<string, TOutputStateExtended>>({});
  const streams = ref<StreamSetupSet[]>([]);

  const importStreams = async (s: StreamSetupSet[]) => {
    streams.value = s;
    for (let index = 0; index < s.length; index++) {
      const stream = s[index];
      await window.importStream?.(stream);
    }
  };

  const clear = () => {
    window.removeAllStream?.();
    Object.keys(statusMap).forEach((key) => {
      delete statusMap[key];
    });
  };

  window.ipcRendererService?.on('stream-state', (event, { id, data }) => {
    if (!id || !data || id === 'studio') return;

    statusMap[id] = data;
    streamStateLogService.post({
      StreamSetupSetId: id,
      State: data,
      CreateDatetime: new Date()
    });
  });

  return {
    importStreams,
    clear,
    statusMap
  };
});
