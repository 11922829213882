/* 外部方法 */
import { DateTime } from 'luxon';
import { onBeforeUnmount, ref } from 'vue';

const timeFuncs = {
  setTimeout: {
    tick: (fn: () => void) => window.setTimeout(fn, 200),
    clearTick: window.clearTimeout
  },
  raf: {
    tick: requestAnimationFrame,
    clearTick: cancelAnimationFrame
  }
};

const defaults = {
  spreator: '-',
  timeZone: 'local',
  timeFunc: 'setTimeout' as keyof typeof timeFuncs
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useClock(options = {} as Partial<typeof defaults>) {
  const { spreator, timeFunc, timeZone } = { ...defaults, ...options };
  const { tick, clearTick } = timeFuncs[timeFunc];
  const date = ref('');
  const time = ref('');

  let timer = 0;
  const tickTock = () => {
    const now = DateTime.now().setZone(timeZone);
    date.value = now.toFormat(`yyyy${spreator}MM${spreator}dd`);
    time.value = now.toFormat('HH:mm:ss');
    timer = tick(tickTock);
  };

  tickTock();
  onBeforeUnmount(() => clearTick(timer));

  return {
    date,
    time
  };
}
