/* 外部方法 */
import { ref, watch, computed, onMounted, type Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { cloneDeep, omit, pick } from 'lodash-es';

/* 內部方法 */
import usePeerStore from '../store/modules/peer';

/* 型別 */
import type useDesk from '@sms/common/composables/useDesk';
import type GameResultSet from '@sms/common/models/GameResultSet';
import type BlankResult from '@sms/common/models/BlankResult';
import type AIServerResponse from '@sms/common/interfaces/AIServerResponse';
import type AIDump from '@sms/common/models/AIDump';

export default function useAiLogger(desk: ReturnType<typeof useDesk>, aiPredictReslut: Ref<BlankResult>) {
  const peerStore = usePeerStore();
  const { startTransfer } = peerStore;
  const { isConnecting, isListening, queueLength } = storeToRefs(peerStore);

  const startPredictDate = ref(new Date());
  const predictTime = ref(0);

  const shouldTransferScreenshot = computed(() => {
    return isConnecting.value && isListening.value && queueLength.value < 1000;
  });

  /**
   * 是否需要回傳該局的辨識結果，如果曾重新登入者，則視為需要回傳
   */
  const isThisRoundNeedToFeedback = useStorage('isThisRoundNeedToFeedback', false);

  const recordStartPredict = () => {
    startPredictDate.value = new Date();
  };

  const recordEndPredict = () => {
    predictTime.value = Date.now() - +startPredictDate.value;
  };

  const getArrayBufferLog = (logData: AIDump<BlankResult>) => {
    // 將資料轉ArrayBuffer 不是 Uint8Array

    const logDataString = JSON.stringify(logData);
    const logDataUint8Array = new TextEncoder().encode(logDataString);
    const logDataArrayBuffer = logDataUint8Array.buffer;

    return logDataArrayBuffer;
  };

  /**
   *
   * @param latestScreenshot 目前截圖
   * @param analysisResult 截圖的分析結果
   */
  const saveScreenshot = (
    latestScreenshot: string,
    aiPokerServerResponse: AIServerResponse[],
    aiHandServerResponse: AIServerResponse[],
    analysisResult: BlankResult
  ) => {
    // 如果沒有截圖，就不儲存
    if (!latestScreenshot) return;

    const logData = cloneDeep<AIDump<BlankResult>>({
      // Id: undefined,
      Image: latestScreenshot,
      DateTime: startPredictDate.value,
      PredictTime: predictTime.value,
      Code: desk.deskData.value.Code,
      Shoe: desk.deskData.value.Shoe.toString(),
      Round: desk.deskData.value.Round.toString(),
      Res: analysisResult,
      GameResult: aiPredictReslut.value,
      Raw: {
        poker: aiPokerServerResponse,
        hand: aiHandServerResponse
      },
      RemoteGameResult: omit(desk.currentGameResult.value, [
        'Id',
        'Shoe',
        'Round',
        'DeskId',
        'LocationId',
        'CreateMemberId',
        'ShoeId',
        'CreateMemberName',
        'Desk'
      ]),
      DeskRealtimeStatus: {
        ...pick(desk.deskData.value, [
          'Id',
          'Name',
          'MaxRoundPerShoe',
          'CountdownLength',
          'MemberId',
          'GameTypeCode',
          'GameStatusCode',
          'PauseStatusCode',
          'SettlingTypeCode',
          'GameModeCode',
          'WarningStatusCode',
          'IsLastRound',
          'IsMaintenance',
          'MemberNickname',
          'MemberForeignId'
        ]),
        // 部分屬性需要手動指定 undefined
        AuxiliaryLineSets: undefined,
        GameResultSets: undefined,
        GameResultSecondSets: undefined,
        GameResultHistorySets: undefined,
        GameResultSecondHistorySets: undefined,
        ShuffleDeskSet: undefined,
        ShuffleBox: undefined,
        // 直接取用現在的倒數時間
        Countdown: desk.countdownSeconds.value,
        TabletHtml: undefined
      }
    });

    if (window.saveScreenshot) {
      window.saveScreenshot(logData);
    }

    if (shouldTransferScreenshot.value) {
      try {
        const file = getArrayBufferLog(logData);

        startTransfer({
          status: true,
          message: 'AI辨識結果',
          name: `${desk.deskData.value.Code}_${desk.deskData.value.Shoe}_${
            desk.deskData.value.Round
          }_${+startPredictDate.value}`,
          size: file.byteLength,
          extension: 'txt',
          file,
          lastModified: new Date().toISOString().slice(0, 19).replace('T', ' ')
        });
      } catch (e) {
        console.error('startTransfer', e);
      }
    }
  };

  /**
   * 結算完成時回報或清除預存資料
   */
  watch(desk.isPausing, (isPausing) => {
    if (isPausing) {
      isThisRoundNeedToFeedback.value = true;
    }
  });

  // 如果剛好遇到最一局，但沒有繼續下一靴，又剛好再也不用這台電腦，那這一局不會傳出來。
  watch(
    () => desk.deskData.value.Round,
    () => {
      if (isThisRoundNeedToFeedback.value) {
        window.uploadScreenshots?.();
      } else {
        window.clearSavedScreenshots?.();
      }

      isThisRoundNeedToFeedback.value = false;
    }
  );

  onMounted(() => {
    let comparePosition: string[] = [];

    switch (desk.deskData.value.GameTypeCode) {
      case 'SIB':
        comparePosition = ['R01', 'R02', 'R03'];
        break;
      case 'ROU':
        comparePosition = ['R01'];
        break;
      case 'DRT':
        comparePosition = ['R01', 'R02'];
        break;
      case 'BAC':
      case 'BJB':
        comparePosition = ['R01', 'R02', 'R03', 'R04', 'R05', 'R06'];
        break;
      default:
        comparePosition = [];
        break;
    }

    isThisRoundNeedToFeedback.value = comparePosition.some(
      (key) => desk.currentGameResult.value[key as keyof GameResultSet]
    );
  });

  return {
    recordStartPredict,
    recordEndPredict,
    saveScreenshot,
    getArrayBufferLog,
    isThisRoundNeedToFeedback
  };
}
